<template>
  <div>
    <!-- User Info: Input Fields -->
    <validation-observer ref="inviteValidation">
      <b-form>
        <b-row>

          <!-- Field: Full Name -->
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="Name"
              label-for="full-name"
            >
              <validation-provider
                #default="{ errors }"
                name="full-name"
                rules="required"
              >
                <b-form-input
                  id="full-name"
                  v-model="userName"
                  autocomplete="off"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Field: Email -->
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="Email"
              label-for="email"
            >
              <validation-provider
                #default="{ errors }"
                name="email"
                rules="required"
              >
                <b-form-input
                  id="email"
                  v-model="userEmail"
                  type="email"
                  autocomplete="off"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

        </b-row>
      </b-form>
    </validation-observer>

    <!-- PERMISSION TABLE -->
    <b-card
      no-body
      class="border mt-1"
    >
      <b-card-header class="p-1">
        <b-card-title class="font-medium-2">
          <feather-icon
            icon="LockIcon"
            size="18"
          />
          <span class="align-middle ml-50">Role</span>
        </b-card-title>
      </b-card-header>
      <b-table
        striped
        responsive
        class="mb-0"
        :items="permissionsData"
      >
        <template #cell(module)="data">
          {{ data.value }}
        </template>
        <template #cell(description)="data">
          {{ data.value }}
        </template>
        <template #cell()="data">
          <b-form-radio
            name="role"
            v-model="selectedRole"
            :value="data.item.module"
          />
        </template>
      </b-table>
    </b-card>

    <!-- PERMISSION TABLE -->
    <b-card
      v-if="canAddPermissions"
      no-body
      class="border mt-1"
    >
      <b-card-header class="p-1">
        <b-card-title class="font-medium-2">
          <feather-icon
            icon="LockIcon"
            size="18"
          />
          <span class="align-middle ml-50">Permissions</span>
        </b-card-title>
      </b-card-header>
      <b-form-checkbox-group
        v-model="selectedPermissions"
        class="m-1"
        :options="rolePermissions[selectedRole]"
      />
    </b-card>

    <!-- Action Buttons -->
    <b-button
      variant="primary"
      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
      @click="validationForm"
    >
      Invite
    </b-button>
  </div>
</template>

<script>
import {
  BButton,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BTable,
  BCard,
  BCardHeader,
  BCardTitle,
  BFormRadio,
  BFormCheckboxGroup,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import { ValidationObserver, ValidationProvider } from 'vee-validate/dist/vee-validate.full'
import { ref } from '@vue/composition-api'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import getRolesData from '@/views/users/roleData'
import handleError from '@/views/components/errorHandler'

export default {
  components: {
    BFormCheckboxGroup,
    BFormRadio,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      userEmail: null,
      userName: null,
      roles: {},
      selectedRole: null,
      permissionsData: [],
      rolePermissions: {
        Viewer: [
          'add manifest',
        ],
      },
      selectedPermissions: [],
    }
  },
  computed: {
    canAddPermissions() {
      return this.selectedRole === 'Viewer'
        && (
          this.$permissions().hasRole('Feeport Admin')
          || this.$permissions().hasRole('Admin')
        )
    },
  },
  created() {
    this.permissionsData = getRolesData()
  },
  methods: {
    validationForm() {
      this.$refs.inviteValidation.validate().then(success => {
        if (!success) {
          return
        }
        this.invite()
      })
    },
    invite() {
      // only 1 can be selected but lets not change existing structure
      this.$data.roles = { [this.selectedRole]: true }
      if (!this.canAddPermissions) {
        this.selectedPermissions = []
      }
      this.$http.post(`/v1/companies/${this.$activeCompany().data.company.id}/invitation/invite`, {
        email: this.$data.userEmail,
        name: this.$data.userName,
        roles: this.$data.roles,
        selectedPermissions: this.selectedPermissions,
      })
        .then(() => {
          this.$router.push('/users')
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'User invited',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          handleError(error, this.$toast)
        })
    },
  },
  setup() {
    // ? Demo Purpose => Update image on click of update
    const refInputEl = ref(null)
    const previewEl = ref(null)

    return {
      avatarText,
      //  ? Demo - Update Image on click of update button
      refInputEl,
      previewEl,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
